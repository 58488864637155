*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    /* width: 100vw; */
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: white;
    background: black;
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.logo {
    position:absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%,-50%,0);
    width:50vw;
    max-width: 720px;
    min-width: 300px;
    height: auto;
    filter:url(#f4);
    object-fit: contain;
    user-select: none;
    pointer-events: none;
}
footer {
    position:absolute;
    bottom:0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding: 1rem;
    text-shadow: 1px 1px 0.5rem rgba(0,0,0,1);
    font-size:0.7rem;
}
